<script setup lang="ts"></script>

<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_501_2616)">
            <path
                d="M23.1408 10.1958H21.9379V6.38669C21.9093 5.92846 21.537 5.55614 21.0788 5.5275H12.8592V0.257812H11.1408V5.5275H2.92124C2.46301 5.55614 2.09069 5.92846 2.06205 6.38669V10.1958H0.859188C0.372315 10.1958 0 10.5681 0 11.0549V16.7256C0 17.2125 0.372315 17.5848 0.859188 17.5848H2.06205V22.8831C2.09069 23.3413 2.46301 23.7137 2.92124 23.7423H21.0788C21.537 23.7137 21.9093 23.3413 21.9379 22.8831V17.5848H23.1408C23.6277 17.5848 24 17.2125 24 16.7256V11.0549C24 10.5681 23.6277 10.1958 23.1408 10.1958ZM5.61337 12.2292C5.61337 10.8545 6.73031 9.73753 8.10501 9.73753C9.47971 9.73753 10.5967 10.8545 10.5967 12.2292C10.5967 13.6039 9.47971 14.7208 8.10501 14.7208C6.73031 14.7208 5.61337 13.6039 5.61337 12.2292ZM15.7804 19.7041H8.21957V17.9857H15.7804V19.7041ZM18.3866 12.2292C18.3866 13.6039 17.2697 14.7208 15.895 14.7208C14.4916 14.7208 13.3747 13.6039 13.3461 12.2292C13.3461 10.8258 14.463 9.70889 15.8377 9.68025C17.2124 9.65161 18.358 10.7972 18.3866 12.1719C18.3866 12.2005 18.3866 12.2005 18.3866 12.2292Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_501_2616">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
